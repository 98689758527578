/* Change this file to get your personal Portfolio */

// To change portfolio colors globally go to the  _globalColor.scss file

import emoji from "react-easy-emoji";
import splashAnimation from "./assets/lottie/splashAnimation"; // Rename to your file name for custom animation

// Splash Screen

const splashScreen = {
  enabled: true, // set false to disable splash screen
  animation: splashAnimation,
  duration: 2000 // Set animation duration as per your animation
};

// Summary And Greeting Section

const illustration = {
  animated: true // Set to false to use static SVG
};

const greeting = {
  username: "Muneeb Arif",
  title: "Hi all, I'm Muneeb",
  subTitle: emoji(
    "A passionate Software Developer 🚀 having an experience of building Web and Mobile applications with JavaScript / ReactJs / AngularJs / NodeJs / Flutter and some other cool libraries and frameworks."
  ),
  resumeLink:
    "https://drive.google.com/file/d/1dRKcxrKQVVFean8mYrMv9GHPJiKRvVHV/view?usp=sharing", // Set to empty to hide the button
  displayGreeting: true // Set false to hide this section, defaults to true
};

// Social Media Links

const socialMediaLinks = {
  github: "https://github.com/munneb10",
  linkedin: "https://www.linkedin.com/in/muneeb-arif-555981173/",
  gmail: "munnebsheikh10@gmail.com",
  medium: "https://medium.com/@munnebsheikh10",
  kaggle: "https://www.kaggle.com/munnebsheikh",
  // Instagram, Twitter and Kaggle are also supported in the links!
  // To customize icons and social links, tweak src/components/SocialMedia
  display: true // Set true to display this section, defaults to false
};

// Skills Section

const skillsSection = {
  title: "What I do",
  subTitle: "A SOFTWARE ENGINEER WHO WANTS TO EXPLORE EVERY TECH STACK",
  skills: [
    emoji(
      "⚡ Develop interactive Front end / User Interfaces for your web and mobile applications"
    ),
    emoji("⚡ Develop scalable Backend application"),
    emoji(
      "⚡ Integration of third party services such as Firebase / AWS / Azure"
    ),
    emoji(
      "⚡ Implement DevOps practices to ensure efficient and continuous delivery of software applications"
    ),
    emoji("⚡ DevOps enthusiast who is passionate about deploying scalable applications and implementing CI/CD pipelines"),
  ],

  /* Make Sure to include correct Font Awesome Classname to view your icon
https://fontawesome.com/icons?d=gallery */

  softwareSkills: [
    {
      skillName: "html-5",
      fontAwesomeClassname: "fab fa-html5"
    },
    {
      skillName: "css3",
      fontAwesomeClassname: "fab fa-css3-alt"
    },
    {
      skillName: "sass",
      fontAwesomeClassname: "fab fa-sass"
    },
    {
      skillName: "JavaScript",
      fontAwesomeClassname: "fab fa-js"
    },
    {
      skillName: "reactjs",
      fontAwesomeClassname: "fab fa-react"
    },
    {
      skillName: "nodejs",
      fontAwesomeClassname: "fab fa-node"
    },
    {
      skillName: "Angular",
      fontAwesomeClassname: "fab fa-angular"
    },
    {
      skillName: "npm",
      fontAwesomeClassname: "fab fa-npm"
    },
    {
      skillName: "sql-database",
      fontAwesomeClassname: "fas fa-database"
    },
    {
      skillName: "aws",
      fontAwesomeClassname: "fab fa-aws"
    },
    {
      skillName: "C++",
      fontAwesomeClassname: "fas fa-code"
    },
    {
      skillName: "python",
      fontAwesomeClassname: "fab fa-python"
    },
    {
      skillName: "docker",
      fontAwesomeClassname: "fab fa-docker"
    }
  ],
  display: true // Set false to hide this section, defaults to true
};

// Education Section

const educationInfo = {
  display: true, // Set false to hide this section, defaults to true
  schools: [
    {
      schoolName: "Arid UIIT, ",
      logo: require("./assets/images/unilogo.jpeg"),
      subHeader: "Bachelors of Science in Software Engineering",
      duration: "September 2020 - June 2024",
      descBullets: [
        "Graduated with a Bachelor's degree in Software Engineering, acquired technical and analytical skills in software development, and knowledge of software design, algorithms, data structures, and database management. Skilled in languages such as Java, Python, and JavaScript, and proficient in frameworks such as Angular, NodeJs / ExpressJs , and React. Fluent in version control systems such as Git and experience with Agile methodologies. Strong problem-solving skills and ability to work effectively in a team environment."
      ]
    }
  ]
};

// Your top 3 proficient stacks/tech experience

const techStack = {
  viewSkillBars: false, //Set it to true to show Proficiency Section
  experience: [
    {
      Stack: "Frontend/Design", //Insert stack or technology you have experience in
      progressPercentage: "90%" //Insert relative proficiency in percentage
    },
    {
      Stack: "Backend",
      progressPercentage: "70%"
    },
    {
      Stack: "Programming",
      progressPercentage: "60%"
    }
  ],
  displayCodersrank: false // Set true to display codersrank badges section need to changes your username in src/containers/skillProgress/skillProgress.js:17:62, defaults to false
};

// Work experience section

const workExperiences = {
  display: true, //Set it to true to show workExperiences Section
  experience: [
    {
      role: "Front-End Engineer",
      company: "Cell Tech",
      companylogo: require("./assets/images/cellTechLogo.webp"),
      date: "Feb 2024 – Present",
      desc: "I am currently working as a Front-End Engineer at Cell Tech. I am working on a technologies like Angular, TypeScript & RxJs. I learned to quickly start working rapidly on an existing codebase.Most Importantly I learned to work collaboartively with my team. I have also learned to work with the latest technologies. I am excited to continue learning and growing as a engineer."
    },
    {
      role: "Mobile Application Developer Intern",
      company: "Pakistan Higher Education Commission",
      companylogo: require("./assets/images/hecLogo.png"),
      date: "Jul 2023 – Sep 2023",
      desc: "Worked as a mobile application developer at Pakistan Higher Education Commission to develop different applications for the commission's various projects. The main project was an e-tendering system for the commission, which was developed using Flutter and Dart."
    },
    {
      role: "FreeLancer",
      company: "Fiverr",
      companylogo: require("./assets/images/fiverrLogo.png"),
      date: "Dec 2019 – May 2020",
      desc: "I was a Freelance web developer. Worked as a web developer on a variety of projects, provided web services to satisfied clients with an average profile rating of 4.8. My expertise included web development, content management systems, e-commerce platforms, and responsive design."
    }
  ]
};

/* Your Open Source Section to View Your Github Pinned Projects
To know how to get github key look at readme.md */

const openSource = {
  showGithubProfile: "true", // Set true or false to show Contact profile using Github, defaults to true
  display: true // Set false to hide this section, defaults to true
};

// Some big projects you have worked on

const bigProjects = {
  title: "Big Projects",
  subtitle: "SOME INSTITUTIONS AND COMPANIES THAT I HELPED TO CREATE THEIR TECH",
  projects: [
    {
      image: require("./assets/images/cellTechLogo.webp"),
      projectName: "Cell Tech Inc",
      // projectDesc: "Lorem ipsum dolor sit amet, consectetur adipiscing elit",
      footerLink: [
        {
          name: "Visit Website",
          url: "https://celltech-usa.com/"
        }
        //  you can add extra buttons here.
      ]
    },
    {
      image: require("./assets/images/hec_company.jpg"),
      projectName: "Pakistan Higher Education Commission",
      // projectDesc: "Lorem ipsum dolor sit amet, consectetur adipiscing elit",
      footerLink: [
        {
          name: "Visit Website",
          url: "https://www.hec.gov.pk/english/Pages/default.aspx"
        }
      ]
    }
  ],
  display: true // Set false to hide this section, defaults to true
};

// Achievement Section
// Include certificates, talks etc

const achievementSection = {
  title: emoji("Achievements And Certifications 🏆 "),
  subtitle:
    "Achievements, Certifications, Award Letters and Some Cool Stuff that I have done !",

  achievementsCards: [
    {
      title: "IBM Certified Data Engineer",
      subtitle:
        "Earned upon completion of IBM Data Engineer Certification course, demonstrating proficiency in data engineering skills and knowledge of IBM technologies.",
      image: require("./assets/images/dataEngCert.jpeg"),
      imageAlt: "Data Engineering Certification",
      footerLink: [
        {
          name: "Certification",
          url: "https://www.credly.com/badges/ad6daaa7-771c-4d62-a47d-875168fa9f70"
        }
      ]
    },
    {
      title: "300+ Days of Daily Solving Challenge",
      subtitle:
        "Solved 500➕ problem solving questions for 300➕ days in 2023. Achieved a Annual Badge 2023 which is awarded to top 0.4% among 1 million➕ users.",
      image: require("./assets/images/leetCodeAch.jpeg"),
      imageAlt: "LeetCode Achievement",
      footerLink: [
        {
          name: "LeetCode Profile",
          url: "https://leetcode.com/u/munnebsheikh10/"
        }
      ]
    },

    {
      title: "AWS Fundamentals Certification",
      subtitle: "Learned Cloud Computing, Network Security, Distributed Computing Architecture, Data Management and Security Engineering with Amazon Web Services",
      image: require("./assets/images/awsFundCert.jpeg"),
      imageAlt: "AWS Certification Logo",
      footerLink: [
        {name: "Certification", url: "https://coursera.org/share/094fe3ecfb1516e15aa24e2561a2bf19"}
      ]
    }
  ],
  display: true // Set false to hide this section, defaults to true
};

// Blogs Section

const blogSection = {
  title: "Blogs",
  subtitle:
    "With Love for Developing cool stuff, I love to write and teach others what I have learnt.",
  displayMediumBlogs: "true", // Set true to display fetched medium blogs instead of hardcoded ones
  blogs: [
    {
      url: "https://medium.com/@munnebsheikh10/hdfs-hadoop-distributed-file-system-unbox-e7f08c0b695b",
      title: "HDFS (Hadoop Distributed File System) Unbox",
      description:
        "A journey into the depths of Hadoop, unboxing the magic..."
    },
    {
      url: "https://medium.com/@munnebsheikh10/why-use-numpy-arrays-instead-of-python-inbuilt-lists-9b0b34124968",
      title: "Why use NumPy arrays instead of Python Inbuilt Lists?",
      description:
        "NumPy arrays offer several advantages over the built-in Python lists when it comes to numerical and scientific computing..."
    }
  ],
  display: true // Set false to hide this section, defaults to true
};

// Talks Sections

const talkSection = {
  title: "TALKS",
  subtitle: emoji(
    "I LOVE TO SHARE MY LIMITED KNOWLEDGE AND GET A SPEAKER BADGE 😅"
  ),

  talks: [
    {
      title: "Build Actions For Google Assistant",
      subtitle: "Codelab at GDG DevFest Karachi 2019",
      slides_url: "https://bit.ly/saadpasta-slides",
      event_url: "https://www.facebook.com/events/2339906106275053/"
    }
  ],
  display: false // Set false to hide this section, defaults to true
};

// Podcast Section

const podcastSection = {
  title: emoji("Podcast 🎙️"),
  subtitle: "I LOVE TO TALK ABOUT MYSELF AND TECHNOLOGY",

  // Please Provide with Your Podcast embeded Link
  podcast: [
    "https://anchor.fm/codevcast/embed/episodes/DevStory---Saad-Pasta-from-Karachi--Pakistan-e9givv/a-a15itvo"
  ],
  display: false // Set false to hide this section, defaults to true
};

// Resume Section
const resumeSection = {
  title: "Resume",
  subtitle: "Feel free to download my resume",

  // Please Provide with Your Podcast embeded Link
  display: true // Set false to hide this section, defaults to true
};

const contactInfo = {
  title: emoji("Contact Me ☎️"),
  subtitle:
    "Whether you want to discuss a project or simply want to say hi, my inbox is always open to you.",
  number: "+92-3345292373",
  email_address: "munnebsheikh10@gmail.com"
};

// Twitter Section

const twitterDetails = {
  userName: "twitter", //Replace "twitter" with your twitter username without @
  display: false // Set true to display this section, defaults to false
};

const isHireable = true; // Set false if you are not looking for a job. Also isHireable will be display as Open for opportunities: Yes/No in the GitHub footer

export {
  illustration,
  greeting,
  socialMediaLinks,
  splashScreen,
  skillsSection,
  educationInfo,
  techStack,
  workExperiences,
  openSource,
  bigProjects,
  achievementSection,
  blogSection,
  talkSection,
  podcastSection,
  contactInfo,
  twitterDetails,
  isHireable,
  resumeSection
};
